<template>
  <transition :name="direction === 'down' ? 'slide-down' : direction === 'up' ? 'slide-up' : null ">
    <div class="supplies-fixed-bar" v-if="isMinified">
      <div class="container-full">
        <div class="row">
          <div class="col-md-12">
            <div class="supplies-fixed-bar-information">
              <div
                class="supplies-fixed-bar-head"
                @click="toggleMinified"
              >
                Passendes Zubehör
              </div>
              <div class="supplies-fixed-bar-description">
                für
                <router-link
                  :to="productLink"
                >
                  {{ currentProduct.name }}
                </router-link>
              </div>
            </div>
            <div class="progress-wrapper">
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
              </div>
            </div>
            <div class="supplies-fixed-bar-action">
              <a
                class="btn btn primary"
                @click="toggleMinified"
              >Zubehör auswählen</a>
            </div>
            <div class="supplies-close">
              <button
                type="button"
                class="close"
                @click="closeSupplies"
                data-testid="closeSupplies"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Supplies from './Supplies'

import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  props: {
    direction: {
      type: String,
      default: 'up'
    }
  },
  mixins: [Supplies],
  computed: {
    productLink () {
      return formatProductLink(this.currentProduct.parent, currentStoreView().storeCode)
    }
  }
}
</script>
